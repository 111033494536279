export const getUserDetails = `
  {
    currentUser {
      username
      isAdmin
      resellerId
      impersonatedBy
      lastLogin
      accounts {
        slug
        name
        url
        style {
          logoUrl
          brandColor
        }
        industry
        country
        countryCode
        currencyCode
        currencySymbol
        currencyPosition
        currencyDecimalPoints
        accountType
        scriptDetected
        timeSlotsEnabled
        activeResellerContract
        hasFoodAndBeverage
      }
      permissions {
        account
        permissions
      }
    }
    profile {
      firstName
      lastName
      email
      contactNo
      avatarUrl
      company {
        name
        role
        industry
        country
        url
      }
      welcomeSeen
      tourSeen
      requiresPasswordChange
      signUp
      emailConfirmed
    }
  }
`
