import { AuthTicket } from 'http/oauthService'

interface AccountStyle {
    logoUrl: string | null
    brandColor: string | null
}

export interface Account {
    slug: string
    name: string
    url: string
    style: AccountStyle | null
    industry: string
    country: string
    countryCode: string
    currencyCode: string
    currencySymbol: string
    currencyPosition: string
    currencyDecimalPoints: number
    accountType: 'partner' | 'self_service'
    scriptDetected: boolean
    timeSlotsEnabled: boolean
    activeResellerContract: boolean
    hasFoodAndBeverage: boolean
}

export interface Permissions {
    account: string
    permissions: string[]
}

export interface User {
    username: string
    lastLogin: string
    name: string
    accounts: Account[]
    permissions: Permissions[]
    isAdmin: boolean
    resellerId: string | null
    impersonatedBy: string | null
}

export interface AuthState {
    version: Readonly<number | null>
    ticket: Readonly<AuthTicket | null>
    user: Readonly<User | null>
    impersonatorTicket: Readonly<AuthTicket | null>
}

export function createInitialAuthState(): AuthState {
    return {
        version: 2,
        ticket: null,
        user: null,
        impersonatorTicket: null,
    }
}

export function applySavedAuthState(state: AuthState | undefined): AuthState {
    const initial = createInitialAuthState()
    const version = initial.version || 0
    if (!state || !state.version || state.version < version) {
        return initial
    }

    return { ...initial, ...state }
}
