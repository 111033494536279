import * as React from 'react'
import styled from 'styled-typed'
import { IconTextInput } from 'uiComponents/input'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { withNavigation } from 'hocs'
import { Navigation } from 'navigation'
import { match as RouteMatch } from 'react-router-dom'

interface SearchProps {
    navigation: Navigation
    match: RouteMatch<any>
    placeholder: string
    style?: React.CSSProperties
    disabled?: boolean
    finishTypingDelay?: number
    width?: string
    className?: string
}

interface SearchState {
    search: string
}

const SearchFieldInput = styled(IconTextInput)<{ width?: string }>`
    background: ${(props) => props.theme.colors.white};
    border: 1px solid ${(props) => props.theme.colors.border};
    border-radius: 4px;
    font-size: 0.75em;
    width: ${(props) => props.width || '17em'};
    height: 2rem;
    padding-left: 3.5em !important;

    &:focus {
        box-shadow: none;
        border: 1px solid ${(props) => props.theme.colors.boyBlue};
    }
`

export const SearchContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`

class SearchField extends React.PureComponent<SearchProps, SearchState> {
    constructor(props: SearchProps) {
        super(props)
        const query = this.props.navigation.query()
        this.state = {
            search: query.q || '',
        }
    }

    componentDidUpdate(prevProps: SearchProps) {
        const prevQuery = prevProps.navigation.query()
        const query = this.props.navigation.query()
        if (query.q !== this.state.search && query.q !== prevQuery.q) {
            this.setState({ search: '' })
        }
    }

    handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ search: e.target.value })
    }

    executeSearch = (query: string) => {
        this.props.navigation.addQueryWithReplace({ q: query.trim(), page: '1' })
    }

    render() {
        return (
            <SearchFieldInput
                iconLeft={faSearch}
                placeholder={this.props.placeholder}
                value={this.state.search}
                onChange={this.handleChange}
                onFinishTyping={this.executeSearch}
                finishTypingDelay={this.props.finishTypingDelay}
                style={this.props.style}
                disabled={this.props.disabled}
                width={this.props.width}
                className={this.props.className}
            />
        )
    }
}

export default withNavigation(SearchField)
